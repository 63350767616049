import React, { useState } from 'react';
import {
  Box, Button, Card, Typography, Table, TableBody, TableCell, TableHead, TableRow,
  Select, MenuItem, FormControl, InputLabel, Pagination, Modal, Paper
} from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

// Função para formatar a data diretamente no formato dd/MM/yyyy
const formatDateToDDMMYYYY = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year}`;
};

const App = () => {
  const [files, setFiles] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // Usado para armazenar dados filtrados
  const [total, setTotal] = useState(0);
  const [availableDates, setAvailableDates] = useState([]); // Datas disponíveis para seleção
  const [selectedDates, setSelectedDates] = useState([]); // Datas selecionadas no filtro
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false); // Estado para controlar o modal
  const rowsPerPage = 10;

  // Função para processar os arquivos XML
  const handleFileUpload = (event) => {
    const uploadedFiles = Array.from(event.target.files);
    setFiles(uploadedFiles);
    const allData = [];
    let totalSum = 0;
    const dates = new Set();

    uploadedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const xmlText = e.target.result;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlText, 'application/xml');
        const atos = xmlDoc.getElementsByTagName('Ato');

        // Extrair a data do campo <DataRealizacaoAto> diretamente
        const dataRealizacaoAto = xmlDoc.getElementsByTagName('DataRealizacaoAto')[0]?.textContent;

        Array.from(atos).forEach((ato) => {
          // Usar a função para formatar a data no formato dd/MM/yyyy
          const date = formatDateToDDMMYYYY(dataRealizacaoAto); // Formatar data sem alteração
          const value = parseFloat(ato.querySelector('Numeracao').getAttribute('ValorSelo')) || 0;
          const description = ato.querySelector('Tipo')?.textContent || 'Sem Descrição';

          allData.push({ date, value, description });
          totalSum += value;
          dates.add(date);
        });

        setData(allData);
        setFilteredData(allData); // Inicialmente, todos os dados são exibidos
        setTotal(totalSum);
        setAvailableDates(Array.from(dates).sort()); // Datas disponíveis para filtro
      };
      reader.readAsText(file);
    });
  };

  // Função para limpar os estados e permitir um novo upload
  const handleClear = () => {
    setFiles([]);
    setData([]);
    setFilteredData([]);
    setTotal(0);
    setSelectedDates([]);
    setAvailableDates([]);
    setCurrentPage(1);
    document.getElementById('upload-input').value = ''; // Limpa o input de arquivos
  };

  // Função para exibir o modal e fechar automaticamente após 3 segundos
  const showModal = () => {
    setOpenModal(true);
    setTimeout(() => {
      setOpenModal(false);
    }, 3000);
  };

  // Função para exportar para CSV, filtrando valores zerados e adicionando quebras de linha a cada 1000 resultados
  const handleExportCsv = () => {
    const exportData = filteredData.length > 0 ? filteredData : data;
    const filteredCsvData = exportData.filter(item => item.value !== 0); // Filtrar valores zerados

    const contentLines = filteredCsvData
        .map(item => `${item.date};R01.001.002;117;${item.value.toFixed(2).replace('.', ',')};;${item.description};PF`);

      
      
  
    // Dividir em blocos de 1000 linhas
    const chunks = [];
    for (let i = 0; i < contentLines.length; i += 1000) {
      chunks.push(contentLines.slice(i, i + 1000));
    }

    chunks.forEach((chunk, index) => {
      const csvContent = chunk.join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `relatorio_part${index + 1}.csv`;
      link.click();
    });

    // Mostrar modal de aviso
    showModal();
  };

  // Função para exportar para PDF com agrupamento por dia e filtragem aplicada
  const handleExportPDF = () => {
    const doc = new jsPDF();
    doc.text('Relatório de Atos', 10, 10);

    // Agrupamento por data, mas agora considerando o filtro aplicado
    const groupedData = filteredData.length > 0 ? filteredData : data;
    const groupedByDate = groupedData.reduce((acc, item) => {
      if (!acc[item.date]) acc[item.date] = [];
      acc[item.date].push(item);
      return acc;
    }, {});

    let resumoFinal = []; // Para armazenar o resumo das datas e valores

    Object.keys(groupedByDate).forEach((date, index) => {
      if (index > 0) doc.addPage();

      // Calcular o total do valor por data
      const totalPorData = groupedByDate[date].reduce((sum, item) => sum + item.value, 0);
      
      // Adicionar ao resumo final
      resumoFinal.push({ date, totalPorData });

      // Exibir a data com o valor total ao lado
      doc.text(`Data: ${date} - Total: R$ ${totalPorData.toFixed(2)}`, 10, 20);
      
      // Tabela de atos do dia
      const rows = groupedByDate[date].map(item => [item.description, item.value.toFixed(2)]);
      doc.autoTable({
        head: [['Descrição', 'Valor']],
        body: rows,
        startY: 30,
      });
    });

    // Adicionar uma página com o resumo final das datas e valores
    doc.addPage();
    doc.text('Resumo Final por Data', 10, 10);
    resumoFinal.forEach((resumo, index) => {
      doc.text(`${resumo.date}: R$ ${resumo.totalPorData.toFixed(2)}`, 10, 20 + (index * 10));
    });

    // Adicionar o total geral no final
    doc.text(`Total Geral: R$ ${total.toFixed(2)}`, 10, 20 + (resumoFinal.length * 10));

    // Salvar o PDF
    doc.save('relatorio.pdf');
  };

  // Função para exportar para Excel com todas as colunas e dados completos
  const handleExportExcel = () => {
    const exportData = filteredData.length > 0 ? filteredData : data;
    const worksheetData = exportData.map(item => ({
      Data: item.date, // Data já formatada
      Descrição: item.description,
      Valor: item.value.toFixed(2),
    }));

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório');
    XLSX.writeFile(workbook, 'relatorio.xlsx');
  };

  // Função para imprimir a tabela filtrada ou completa
  const handlePrint = () => {
    const printData = filteredData.length > 0 ? filteredData : data;

    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write('<html><head><title>Relatório</title></head><body>');
    printWindow.document.write('<h1>Relatório de Atos</h1>');
    printWindow.document.write('<table border="1"><tr><th>Data</th><th>Descrição</th><th>Valor</th></tr>');

    printData.forEach((item) => {
      printWindow.document.write(`<tr><td>${item.date}</td><td>${item.description}</td><td>R$ ${item.value.toFixed(2)}</td></tr>`);
    });

    printWindow.document.write('</table>');
    printWindow.document.write(`<p>Total Geral: R$ ${total.toFixed(2)}</p>`);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  // Filtrar dados por data
  const handleFilter = () => {
    if (selectedDates.length > 0) {
      const filtered = data.filter(item => selectedDates.includes(item.date));
      setFilteredData(filtered);
    } else {
      setFilteredData(data); // Se nenhuma data for selecionada, mostrar todos os dados
    }
  };

  // Atualizar a página
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Dados para exibição na página atual
  const paginatedData = filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" bgcolor="#f0f0f0">
      <Card variant="outlined" sx={{ padding: '20px', width: '90%', boxShadow: 3 }}>
        <Typography variant="h5" align="center" gutterBottom>
          XML Cartorio
        </Typography>

        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" gap={2}>
          <Button variant="outlined" component="label">
            Selecionar Arquivos XML
            <input
              type="file"
              accept=".xml"
              id="upload-input"
              multiple
              onChange={handleFileUpload}
              style={{ display: 'none' }}
            />
          </Button>

          <Box display="flex" justifyContent="space-between" width="100%" gap={2} mt={2}>
            <FormControl fullWidth>
              <InputLabel>Filtrar por Datas</InputLabel>
              <Select
                multiple
                value={selectedDates}
                onChange={(e) => setSelectedDates(e.target.value)}
                renderValue={(selected) => selected.join(', ')}
              >
                {availableDates.map((date) => (
                  <MenuItem key={date} value={date}>
                    {date}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button variant="contained" onClick={handleFilter}>
              Aplicar Filtros
            </Button>
          </Box>

          <Typography variant="h6">
            Total dos valores dos selos: R$ {total.toFixed(2)}
          </Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Valor</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.date}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>R$ {item.value.toFixed(2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Pagination
            count={Math.ceil(filteredData.length / rowsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />

          <Box display="flex" justifyContent="space-between" width="100%" mt={3}>
            <Button variant="contained" color="primary" onClick={handleExportPDF}>
              Exportar PDF
            </Button>
            <Button variant="contained" color="secondary" onClick={handleExportExcel}>
              Exportar Excel
            </Button>
            <Button variant="contained" color="default" onClick={handleExportCsv}>
              Exportar CSV
            </Button>
            <Button variant="contained" color="default" onClick={handlePrint}>
              Imprimir
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleClear}>
              Limpar Filtros
            </Button>
          </Box>
        </Box>
      </Card>

      {/* Modal para exibir mensagem sobre valores zerados */}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Atenção
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Valores zerados não serão incluídos na exportação.
          </Typography>
        </Paper>
      </Modal>
    </Box>
  );
};

export default App;
